



























  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { ProductMotorState } from '@/store/product/motor/types';
  import { AboutModel, ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'productProperty',
    components: {
      compareProperty: () => import('@/components/formCompare/compareProperty.vue'),
      productHelp: () => import('@/components/ProductHelp.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class productProperty extends Vue {
    @State((state: ProductMotorState) => state.title.HTMLtoString(), { namespace: 'product/properti' }) title!: string;
    @State((state: ProductMotorState) => state.content.HTMLtoString(), { namespace: 'product/properti' }) content!: string;
    // @State((state: ProductMotorState) => state.banner.HTMLtoString(), { namespace: 'product/properti' }) banner !: string;
    @State('page_content') page_content!: ContentModel[];
    @State('abouts') abouts!: AboutModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'properti-banner')[0]?.image ?? null;
    }

    public get helpImg(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'properti-help')[0]?.image ?? null;
    }

    public get information(): ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'properti-information')[0] ?? new ContentModel();
    }

    public get aboutApp() : AboutModel {
      return this.abouts.filter((v)=>v.id === 0)[0]
    }

    public banerDefault = require('@/assets/images/banner/properti.jpg');

    public get listBannerInfo() :ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.SLIDER && item.name === 'properti-slider')
    }

    public created(): void {
      // on componenet created
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
